export function scrollTariff() {
    let isDown = false
    let startX
    let scrollLeft

    function handleMouseDown(e) {
        isDown = true
        this.classList.add('active')
        startX = e.pageX - this.offsetLeft || e.touches[0].pageX - this.offsetLeft
        scrollLeft = this.scrollLeft
    }

    function handleMouseLeave() {
        isDown = false
        this.classList.remove('active')
    }

    function handleMouseUp() {
        isDown = false
        this.classList.remove('active')
    }

    function handleMouseMove(e) {
        // eslint-disable-next-line
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - this.offsetLeft || e.touches[0].pageX - this.offsetLeft
        const walk = (x - startX) * 1.5
        this.scrollLeft = scrollLeft - walk
    }

    function attachEventListeners(container) {
        container.addEventListener('mousedown', handleMouseDown)
        container.addEventListener('mouseleave', handleMouseLeave)
        container.addEventListener('mouseup', handleMouseUp)
        container.addEventListener('mousemove', handleMouseMove)

        container.addEventListener('touchstart', handleMouseDown, { passive: true })
        container.addEventListener('touchend', handleMouseUp)
        container.addEventListener('touchmove', handleMouseMove, { passive: true })
    }

    function detachEventListeners(container) {
        container.removeEventListener('mousedown', handleMouseDown)
        container.removeEventListener('mouseleave', handleMouseLeave)
        container.removeEventListener('mouseup', handleMouseUp)
        container.removeEventListener('mousemove', handleMouseMove)
        container.removeEventListener('touchstart', handleMouseDown)
        container.removeEventListener('touchend', handleMouseUp)
        container.removeEventListener('touchmove', handleMouseMove)
    }

    document.querySelectorAll('.tariff-content--active').forEach(attachEventListeners)

    document.querySelectorAll('.switch-theme__btn').forEach(tab => {
        tab.addEventListener('click', () => {
            document.querySelectorAll('.tariff-content--active').forEach(container => {
                detachEventListeners(container)
                attachEventListeners(container)
            })
        })
    })
}
